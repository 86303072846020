import React from 'react';
import PageHeader from '../Components/common/PageHeader';
import Map from '../Components/ContactElements/Map';
import Banner from '../Containers/Banner';
import {SiteData} from "../Constants/siteData";
import ContactFormSection from "../Components/ContactFormSection/ContactFormSection";
import {SEO} from "../Components/SEO/SEO";
import {Box} from "@chakra-ui/react";

const ContactPage = () => {
    return (
        <>
            <SEO/>
            <PageHeader header={SiteData.contact.h1} subtitle={SiteData.contact.subtitle}/>
            <div className="main_wrapper">
                <Box py={{base: 10, md: 10}}>
                    <div className="container contact-page">
                        <div className="gmapbox">
                            <div id="googleMap" className="map">
                                <Map height={'450'}/>
                            </div>
                        </div>
                    </div>
                </Box>
                <ContactFormSection data={[{
                    id: '1',
                    iconClass: 'ion-ios-telephone-outline',
                    infoBody01: 'Call us Today!',
                    infoBody02: SiteData.phoneNumber,
                    link: SiteData.telLink,
                    heading: 'Get a FREE estimate',
                    cta: 'Provide some information and get a FREE quote today'
                }]}/>
                <Banner
                    title={SiteData.contact.closingText}
                    heading='Call Us Anytime'
                    phone={SiteData.phoneNumber}
                    bannerType='banner banner_bg_color'
                />
            </div>

        </>
    );
};

export default ContactPage;