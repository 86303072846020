import {createBrowserRouter} from 'react-router-dom';
import HomeLayout from '../Layouts/HomeLayout';
import About from '../Pages/About';
import ContactPage from '../Pages/ContactPage';
import Home01 from '../Pages/Homes/Home01';
import Services01 from '../Pages/Services/Services01';
import ErrorPage from '../Pages/ErrorPage/ErrorPage';
import Blog from "../Pages/Blog";
import ADASCalibration from "../Pages/Services/ADASCalibration";
import RearWindowRepair from "../Pages/Services/RearWindowRepair";
import SideWindowRepair from "../Pages/Services/SideWindowRepair";
import WindshieldRepair from "../Pages/Services/WindshieldRepair";
import WindshieldReplacement from "../Pages/Services/WindshieldReplacement";
import MobileAutoGlassServices from "../Pages/Services/MobileAutoGlassServices";
import Careers from "../Pages/Careers";
import React from "react";
import {BlogPost} from "../Layouts/BlogPost";


export const routes = createBrowserRouter([
    {
        path: "/",
        element: <HomeLayout/>,
        children: [
            {
                path: '/',
                element: <Home01/>
            },
            {
                path: '/blog',
                element: <Blog/>
            },
            {
                path: '/services',
                element: <Services01/>
            },
            {
                path: '/windshield-repair',
                element: <WindshieldRepair/>
            },
            {
                path: '/mobile-auto-glass-services',
                element: <MobileAutoGlassServices/>
            },
            {
                path: '/windshield-replacement',
                element: <WindshieldReplacement/>
            },
            {
                path: '/rear-window-repair',
                element: <RearWindowRepair/>
            },
            {
                path: '/side-window-repair',
                element: <SideWindowRepair/>
            },
            {
                path: '/adas-calibration',
                element: <ADASCalibration/>
            },
            {
                path: '/about',
                element: <About/>
            },
            {
                path: '/careers',
                element: <Careers/>
            },
            {
                path: '/contact',
                element: <ContactPage/>
            },
            {
                path: '/blog/:id',
                element: <BlogPost/>
            },
            {
                path: '*',
                element: <ErrorPage/>
            },
        ]
    }
])

