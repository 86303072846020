import {Box, Button, ButtonGroup, Container, css, HStack, SlideFade, Stack} from '@chakra-ui/react'
import {DocumentPopover} from './DocumentPopover'
import {Logo} from './Logo'
import {MobileDrawer} from './MobileDrawer'
import {SiteData} from "../../../Constants/siteData";
import React, {useEffect, useState} from "react";
import {FaPhone} from "react-icons/fa";

export const NavBar = () => {
    const [isOpen, setIsOpen] = useState(false)
    useEffect(() => {
        setIsOpen(true)
    }, [])
    return (
      <Box as="section" minW={'100%'}>
          <Box borderBottomWidth="1px" bg="bg.surface" position="relative" zIndex="tooltip" minW={'100%'}>
              <Container py="2"  minW={'100%'}>
                  <Box maxW={'1170px'}
                       mx={'auto'}
                  >
                      <HStack justify="space-between" spacing={{base: 0, md: 8}}>
                          <HStack spacing="10">
                              <HStack spacing="3">
                                  <MobileDrawer/>
                                  <Logo/>
                              </HStack>
                              <ButtonGroup
                                size="lg"
                                variant="text"
                                colorScheme="gray"
                                spacing="8"
                                display={{
                                    base: 'none',
                                    lg: 'flex',
                                }}
                                css={css`
                                      border: 0;
                                      font-family: "Nunito Sans", sans-serif;
                                      font-size: 17px;
                                      line-height: 26px;
                                      position: relative;
                                      text-align: center;
                                      font-weight: 700;
                                      text-decoration: none;`}
                              >
                                  {SiteData.navLinks.map(({title, href, hideFromTopNav}) => {
                                      if (hideFromTopNav) {
                                          return null
                                      }
                                      if (title === 'Services') {
                                          return (
                                            <DocumentPopover items={SiteData.homepage.featuredServices}
                                                             title={title} key={title} href={href}/>
                                          )
                                      }
                                      return (
                                        <Button as={'a'} href={href} key={title}>{title}</Button>
                                      )
                                  })}
                              </ButtonGroup>
                          </HStack>
                          <Stack
                            direction={{
                                base: 'column',
                                md: 'row',
                            }}
                            spacing="3"
                            align={{
                                base: 'stretch',
                                md: 'center',
                            }}
                          >
                              <SlideFade direction='top' in={isOpen} style={{zIndex: 10}} offsetY='70px'
                                         transition={{exit: {delay: 1}, enter: {duration: 0.5}}}>
                                  <Button rightIcon={<FaPhone/>} variant='ghost' size='lg' minH={'60px'} as={'a'}
                                          href={SiteData.telLink} _hover={{bg: 'white'}}>
                                      Call Us Anytime<br/>
                                      {SiteData.phoneNumber}
                                  </Button>
                              </SlideFade>
                          </Stack>
                      </HStack>
                  </Box>
              </Container>
          </Box>
      </Box>
    )
}
